import request from "../request";
let setParams = (obj) => {
  let result = '';
  let item;
  for (item in obj) {
    if (obj[item] && String(obj[item])) {
      result += `&${item}=${obj[item]}`;
    }
  }
  if (result) {
    result = result.slice(1);
  }
  return result;

}
export default class Aircraft {
  /**
   * 列表
   * @param {*} data
   * @returns
   */
  static PageList(params) {
    return request.get("/aircraftsystemserver/aircraft/pageList", { params });
  }
  /**
  * 审核
  * @param {*} data
  * @returns
  */
  static approval(params) {
    return request.post("/aircraftsystemserver/aircraft/approval/", setParams(params),
    );
  }

  /**
  * 审核
  * @param {*} data
  * @returns
  */
  static flightApproval(params) {
    return request.post("/aircraftsystemserver/flight/approval/", setParams(params),
    );
  }

  /**
* 证件审核
* @param {*} data
* @returns
*/
  static certificateInfoApproval(params) {
    return request.post("/aircraftsystemserver/certificateInfo/approval/", setParams(params),
    );
  }
  /**
* 借用 
* @param {*} data
* @returns
*/
  static borrowList(params) {
    return request.post("/aircraftsystemserver/borrow/list/", params,
    );
  }

  /**
 * 借用 
 * @param {*} data
 * @returns
 */
  static certificateInfoLists(params) {
    return request.post("/aircraftsystemserver/certificateInfo/lists/", params,
    );
  }
  /**
 * 报飞备案 
 * @param {*} data
 * @returns
 */
  static flightPage(params) {
    return request.post("/aircraftsystemserver/flight/page", params,
    );
  }

  /**
 * 飞行计划 数量
 * @param {*} data
 * @returns
 */
  static flightCount(params) {
    return request.get("/aircraftsystemserver/flight/count", { params });
  }


  /**
* 公告管理
* @param {*} data
* @returns
*/
  static noticeInfoGetList(params) {
    return request.post("/aircraftsystemserver/noticeInfo/getList/", params,
    );
  }
  /**
* 公告管理-删除
* @param {*} data
* @returns
*/
  static noticeInfoDel(params) {
    return request.delete(`/aircraftsystemserver/noticeInfo/del/?id=${params.id}`,
    );
  }

  /**
* 公告管理
* @param {*} data
* @returns
*/
  static noticeInfoSave(params) {
    return request.post("/aircraftsystemserver/noticeInfo/save/", params,
    );
  }
  /**
* 公告管理
* @param {*} data
* @returns
*/
  static upInfo(params) {
    return request.put("/aircraftsystemserver/noticeInfo/upInfo/", setParams(params),
    );
  }

  /**
* 意见管理
* @param {*} data
* @returns
*/
  static feedbackLists(params) {
    return request.post("/aircraftsystemserver/feedback/lists/", params,
    );
  }

  /**
* 意见管理-删除
* @param {*} data
* @returns
*/
  static feedbackDel(params) {
    return request.delete(`/aircraftsystemserver/feedback/del/?id=${params.id}`,
    );
  }

  /**
* 人员信息列表
* @param {*} data
* @returns
*/
  static UserInfo(params) {
    return request.get("/aircraftsystemserver/the/getInfo", { params });
  }

  /**
* 人员信息列表-启用禁用
* @param {*} data
* @returns
*/
  static UserFreeze(params) {
    return request.get("/aircraftsystemserver/the/freeze", { params });
  }
  /**
* 无人机转让列表
* @param {*} data
* @returns
*/
  static pageTran(params) {
    return request.get("/aircraftsystemserver/aircraft/pageTran", { params });
  }

  /**
* 无人机转让
* @param {*} data
*/
  static makeOver(params) {
    return request.post("/aircraftsystemserver/aircraft/makeOver", { params });
  }

  // 首页数据---------------------------------------------------------------------------------------------
  /**
* 今日新增数据
* @param {*} data
* @returns
*/
  static getToday(params) {
    return request.get("/aircraftsystemserver/screen/getToday", { params });
  }
  /**
* 报飞备案
* @param {*} data
* @returns
*/
static getReported(params) {
  return request.get("/aircraftsystemserver/screen/getReported", { params });
}

  
  /**
* 人员统计
* @param {*} data
* @returns
*/
  static getPeople(params) {
    return request.get("/aircraftsystemserver/screen/getPeople", { params });
  }
  /**
* 热点区域
* @param {*} data
* @returns
*/
  static getHot(params) {
    return request.get("/aircraftsystemserver/screen/getHot", { params });
  }

  /**
* 飞行器同统计
* @param {*} data
* @returns
*/
  static getFlightCount(params) {
    return request.get("/aircraftsystemserver/screen/getFlightCount", { params });
  }

  /**
* 飞手活跃度
* @param {*} data
* @returns
*/
  static getFlight(params) {
    return request.get("/aircraftsystemserver/screen/getFlight", { params });
  }

  /**
* 用途列表
* @param {*} data
* @returns
*/
static listUsage(params) {
  return request.get("/aircraftsystemserver/spinnerList/listUsage", { params });
}
  /**
* 产品类型列表
* @param {*} data
* @returns
*/
static listProduct(params) {
  return request.get("/aircraftsystemserver/spinnerList/listProduct", { params });
}

  /**
* 航空器登记导入excel
* @param {*} data
*/
static aircraftImport(params) {
  return request.post("/aircraftsystemserver/aircraft/import",params);
}


  /**
* 证照管理导入excel
* @param {*} data
*/
static certificateInfImport(params) {
  return request.post("/aircraftsystemserver/certificateInfo/import", { params });
}
  /**
* 报飞备案导入excel
* @param {*} data
*/
static flightImport(params) {
  return request.post("/aircraftsystemserver/flight/import",params);
}


}