import request from "../request";

export default class Dashboard {
// 数据推送

// /statistics/warningPush
static warningPush(params) {
  return request.get(`/hawksystemserver/statistics/warningPush`, params);
}

    /**
* 数据分析 -预警信息统计
* @param {*} data
* @returns
*/
static statisticsWarning(params) {
  return request.get("/hawksystemserver/statistics/warning", { params });
}
  //删除 预警
  static multiDelete(params) {
    return request.put(`/aiserver/kaola/multiDelete`, params);
  }

  /**
* 设备列表
* @param {*} data
* @returns
*/
  static allDevice(params) {
    return request.get("/hawksystemserver/device/allDevice", { params });
  }
  /**
* 预警列表
* @param {*} data
* @returns
*/
  static warningList(params) {
    return request.get("/aiserver/kaola/warningList", { params });
  }
  /**
 * 用户列表
 * @param {*} data
 * @returns
 */
  static userLoginList(params) {
    return request.get("/hawksystemserver/user/userLoginList", { params });
  }
  /**
 * 机构任务数量排行
 * @param {*} data
 * @returns
 */
  static organRank() {
    return request.get("/hawksystemserver/user/organRank");
  }
  /**
 * 所有机构用户数量
 * @param {*} data
 * @returns
 */
  static organUserCount() {
    return request.get("/hawksystemserver/user/organUserCount");
  }
  //   机构对应用户数量
  static organUserList() {
    return request.get("/hawksystemserver/user/organUserList");
  }



  /**
   * 用户任务及飞行统计
   * @param {*} data
   * @returns
   */
  static userstatistic() {
    return request.get("/hawksystemserver/user/statistic");
  }

  /**
   * 用户角色列表
   * @param {*} data
   * @returns
   */
  static roleList() {
    return request.get("/hawksystemserver/role/roleList");
  }
  /**
   * 机构
   * @param {*} data
   * @returns
   */
  static getAllOrganization() {
    return request.get("/hawksystemserver/organization/getAllOrganization?pageNo=1");
  }


  /**
* 注册用户 
* @param {*} data
* @returns
*/
  static registerUser(data) {
    return request.post("/hawksystemserver/user/registerUser", data,
    );
  }
  /**
* 修改用户 
* @param {*} data
* @returns
*/
  static modifyInfo(data) {
    return request.post("/hawksystemserver/user/modifyInfo", data,
    );
  }


  /**
   * 机构
   * @param {*} params
   * @returns
   */
  //  static selectLikeList(params) {
  //   return request.get("/hawksystemserver/user/getInfoById",{params});
  // }
  static selectLikeList(id) {
    return request.get("/hawksystemserver/user/getInfoById/" + id);
  }

  /**
   * 人员排行
   * @param {*} params
   * @returns
   */
  static statisticsRank(params) {
    return request.get("/hawksystemserver/statistics/person-rank", { params });
  }

}
