import axios from 'axios'

const key = "16be596e00c44c86bb1569cb53424dc9"
const appKey = "30c6030f6e144f08b51f7781699276c3"

let isSwtxDev = (process.env.NODE_ENV === "development")
export default class Map {
    static Geo(params) {
        let promise = isSwtxDev ? axios.get('https://minedata.cn/service/lbs/coder/geocoding2', {
            params: {
                key,
                city: "全国",
                page_size: 20,
                ...params
            }
        }) : axios.get("http://32.128.12.138:21009/service/lbs/coder/geocoding2", {
            params: {
                appKey,
                city: "全国",
                page_size: 20,
                ...params
            }
        })
        return promise
    }
}