import mqtt from "mqtt";
const { VUE_APP_FKMTQQ: FCMQTT_URL } = process.env;
import { Message } from 'element-ui'
export default {
    namespaced: true,
    state: {
        client: null,
        isConnected: false,
        uavRealTimeData: {},
        orders: {
            "无人机模式切换": 513,
            "云台控制指令透传": 514,
            "原地起飞": 515,
            "原地降落": 516,
            "返航指令": 517,
            "航线控制": 519,
            "航线下载": 520,
            "航线上传": 521,
            "航线一键起飞": 522,
            "暂停飞行": 523,
            "继续飞行": 524,
            "结束航线": 525,
            "MAVLink数据包透传": 526,
            "激活无人机": 527,
            "NX控制指令": 528,
            "无人机参数设置": 530,
            "无人机警灯控制": 531,//无人机警灯 尾灯 降落伞 隐蔽控制
            "无人机参数设置": 532,
            "强制打开降落伞": 533,
            "COMMAND_LONG指令": 534,
            "执行中的航线任务": 535,
            "绑定任务id": 769,
            "链路切换": 770,
        },
        airlineData: null,//航线数据
        uav: null,//当前操作无人机数据
        mountList: null,//挂载数据
        flyData: null,//无人机数据
        loggerList: [],//运行监测日志
        flightMode: "",//飞机当前飞行模式
        healthData: {},//健康管理,
        refreshTask: null,//刷新任务列表
        getBattery: null,//电池数据

    },
    mutations: {
        SET_CLIENT(state, client) {
            state.client = client;
        },
        set_loggerList(state, data) {
            state.loggerList.unshift(data)
        },
        SET_CONNECTED(state, isConnected) {
            state.isConnected = isConnected;
        },
        /**
         * 单纯的给state赋值
         * @param {*} param0
         * @param {*} data {key: '', value}
         */
        setState(state, data) {
            try {
                state[data.key] = data.value;
            } catch (e) {
                console.log("setDate err", e);
            }
        },
    },
    actions: {
        connect({ state, commit, dispatch }) {
            console.log(111);
            if (state.client) return false;
            const client = mqtt.connect(FCMQTT_URL); // 替换为实际的MQTT代理地址
            client.on('connect', () => {
                commit('SET_CLIENT', client);
                commit('SET_CONNECTED', true);
                // 订阅数据
                let topic = `PX4/RECEIVE/${state.uav.deviceHardId}`
                console.log(topic, '订阅数据');
                dispatch("subscribe", topic)
            });

            client.on('close', () => {
                commit('SET_CONNECTED', false);
            });

            // 例如，接收消息
            client.on('message', (topic, message) => {
                let data = JSON.parse(message.toString())
                let deviceHardId = state.uav.deviceHardId
                // 挂载数据
                if (topic.indexOf(deviceHardId) != -1) {
                    if (data.type == 259) {
                        commit('setState', {
                            key: 'mountList',
                            value: data.data
                        })
                    } else if (data.type == 258) {
                        // 无人机飞行数据
                        commit('setState', {
                            key: 'flyData',
                            value: data.data
                        })
                    } else if (data.type == 260) {
                        commit('set_loggerList', {
                            type: '警告',
                            text: data.data.msg
                        })
                    } else if (data.type == 269) {
                        Message.info(data.data)
                    } else if (data.type == 270) {
                        commit('set_loggerList', {
                            type: data.data.code,
                            text: data.data.text
                        })
                        if (data.data.code == 10043) {
                            Message.success(data.data.text)
                        } else if (data.data.code == 10053) {
                            Message.success(data.data.text)
                        }
                    }
                    //健康管理
                    if (data.type == 275) {
                        commit('setState', {
                            key: 'healthData',
                            value: data.data
                        })
                    }
                    // 电池数据
                    if (data.type === 257) {
                        commit('setState', {
                            key: 'getBattery',
                            value: data.data
                        })
                    }
                    if (data.type === 272) {
                        //避障信息
                        commit('setState', {
                            key: 'obstacle',
                            value: data.data
                        })
                    }

                }
            });
        },
        disconnect({ state, commit }) {
            if (state.client) {
                state.client.end()
                commit('SET_CLIENT', null)
                commit('SET_CONNECTED', false)
                commit('setState', {
                    key: 'uav',
                    value: null
                })
                commit('setState', {
                    key: 'loggerList',
                    value: []
                })
            }
        },
        /**
        * 销毁无人机相关对象
        * @param {} param0
        * @param {*} data
        */
        destroy({ commit, state, dispatch }, data) {
            commit("setState", { key: "uav", value: null });
            commit("setState", { key: "airlineData", value: null });
            commit("setState", { key: "flyData", value: null });
            commit("setState", { key: "loggerList", value: [] });
            commit("setState", { key: "healthData", value: {} });
            commit("setState", { key: "getBattery", value: null });

        },
        // 例如，订阅主题
        subscribe({ state }, topic) {
            state.client.subscribe(topic);
        },
        // 其他操作，例如发布消息
        publishMessage({ state }, { topic, data, callback }) {
            console.log(state.client, 'state.isConnected');
            if (state.isConnected) {
                state.client.publish(topic, JSON.stringify(data), (err) => {
                    if (err) {
                        console.log('数据操作失败:', err);
                        callback && callback(false)
                    } else {
                        console.log('数据操作成功');
                        console.log(data, topic, '发布消息');
                        callback && callback(true)

                    }
                });
            }
        },
        // 操作
        /** 一键起飞
        * @param {function} data.callback  //完成回调
        */
        async takeOff({ state, dispatch }, data) {
            try {
                const flyTime = Date.now();
                // 生成架次號
                let getFlightSortic = "FLY-" + flyTime + "-" + state.uav.deviceHardId
                // 航线数据
                let waypointList = state.airlineData?.content
                console.log('航线数据', {
                    taskId: state.airlineData.taskId,
                    flightSortiesID: getFlightSortic,
                    waypointList: waypointList,
                    autoFlightSpeed: 6,
                    finishedAction: "GO_HOME",
                    headingMode: "AUTO",
                    isExitMissionOnRCSignalLostEnabled: true,
                    maxFlightSpeed: 12,
                });
                dispatch("publishMessage", {
                    topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                    data: {
                        type: state.orders.航线上传,
                        data: {
                            taskId: state.airlineData.taskId,
                            flightSortiesID: getFlightSortic,
                            waypointList: waypointList,
                            autoFlightSpeed: 6,
                            finishedAction: "GO_HOME",
                            headingMode: "AUTO",
                            isExitMissionOnRCSignalLostEnabled: true,
                            maxFlightSpeed: 12,
                        },
                    },
                    callback() { },
                });

                // 告诉飞控开始任务,并且把架次号和 任务id传过去
                dispatch("publishMessage", {
                    topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                    data: {
                        type: state.orders.绑定任务id,
                        data: {
                            taskId: state.airlineData.taskId,
                            flightSortiesID: getFlightSortic,
                        },
                    },
                    callback() { },
                });

                // 更改任务状态
                //起飞指令
                setTimeout(() => {
                    dispatch("publishMessage", {
                        topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                        data: {
                            type: state.orders.航线一键起飞,
                            data: {
                                taskId: state.airlineData.taskId,
                                seq: 0,
                            },
                        },
                        callback() {
                            data.callback && data.callback(true);
                        },
                    });
                }, 3000);
            } catch (e) {
                console.log("一键起飞失败", e);
                data.callback && data.callback(false);
            }
        },
        /**
        * 任务结束
        * @param {function} data.callback  //完成回调
        */
        async takeEnd({ state, dispatch }, data) {
            try {
                // 结束航线指令
                dispatch("publishMessage", {
                    topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                    data: {
                        type: 525,
                        data: null,
                    },
                    callback() {
                        data.callback && data.callback(true);
                    },
                });
            } catch (e) {
                console.log("任务结束失败", e);
                data.callback && data.callback(false);
            }
        },
        /**
        * 安全降落
        * @param {*} param0
        * @param {*} data
        */
        land({ state, dispatch }, data) {
            dispatch("publishMessage", {
                topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                data: {
                    type: state.orders.原地降落,
                },
                callback() {
                    data.callback && data.callback(true);
                },
            });
        },
        /**
        * 暂停飞行
        * @param {*} param0
        * @param {*} data
        */
        pauseFly({ state, dispatch }, data) {
            dispatch("publishMessage", {
                topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                data: {
                    type: state.orders.暂停飞行,
                },
                callback() {
                    data.callback && data.callback(true);
                },
            });
        },
        /**
         * 继续飞行
         * @param {*} param0
         * @param {*} data
         */
        continueFly({ state, dispatch }, data) {
            console.log('继续飞行');
            dispatch("publishMessage", {
                topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                data: {
                    type: state.orders.继续飞行,
                },
                callback() {
                    data.callback && data.callback(true);
                },
            });
        },
        /**
        * 一键返航
        * @param {*} param0
        * @param {*} data
        */
        returnFlight({ state, dispatch }, data) {
            dispatch("publishMessage", {
                topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                data: {
                    type: state.orders.返航指令,
                },
                callback() {
                    data.callback && data.callback(true);
                },
            });

        },
        /**
         * 指点飞行 控制飞机在 速度 位置 
         * @param {*} param0
         * @param {*} data
         */
        routeControl({ state, dispatch }, data) {
            dispatch("publishMessage", {
                topic: "PX4/OBTAIN/" + state.uav.deviceHardId,
                data: {
                    type: state.orders.航线控制,
                    data: {
                        latitude: data.latitude,
                        longitude: data.longitude,
                        speed: data.speed,
                    },
                },
                callback() {
                    data.callback && data.callback(true);
                },
            });
        },
        modePOSITION({ state, dispatch }, data) {
            dispatch("MMCMQTT/publish", {
                topic: "PX4/OBTAIN/" + state.uav.hardId,
                data: {
                    type: state.orders.无人机模式切换,
                    data: "POSITION",
                },
                callback() {
                    data.callback && data.callback(true);
                },
            });

        },

    }
};
;