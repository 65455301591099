import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

import getters from './getters'

const ctx = require.context('./modules', true, /\.js$/)
console.log(ctx, 'ctx');
let modules = ctx.keys().reduce((modules, key) => {
    // ./app.js => app
    let name = key.replace(/^\.\/(.*)\.js$/, '$1'),
    value = ctx(key).default
    modules[name] = value
    return modules
}, {})
const store = new Vuex.Store({
    modules,
    getters,
})

export default store