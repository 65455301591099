import { axios } from "../request";

class Report {
  static reportDetail(id) {
    // return axios.get(`/hawksystemserver/task-web/reportDetail/${id}`);
    return axios.get(`/hawksystemserver/taskOrder/reportDetail/${id}`);
  }
  static reportList(params) {
    return axios.get(`/hawksystemserver/task-web/reportList`, { params } );
  }

}

export default Report;
