import request from "../request";

export default class Airspace {
    /**
 * 空域列表
 * @param {*} data
 * @returns
 */
  static selectLikeList(params) {
    return request.get("/hawksystemserver/airspace/web_selectLikeList", { params });
  }

    /**
 * 空域类型列表
 * @param {*} data
 * @returns
 */
  static getTypeList() {
    return request.get("/hawksystemserver/airspacetype/getTypeList");
  }
  
  /**
 * 新增空域
 * @param {*} data
 * @returns
 */
   static airspaceAdd(params) {
    return request.post("/hawksystemserver/airspace/addAirSpaceWeb", params);
  }
  
  /**
 * 空域删除
 * @param {*} data
 * @returns
 */
   static del(params) {
    return request.get("/hawksystemserver/airspace/modifyStatus", {params});
  }

  /**
 * 空域编辑
 * @param {*} data
 * @returns
 */
   static edit(params) {
    return request.post("/hawksystemserver/airspace/modifyInfo", params);
  }


  // 导入空域
  static airUpload(params) {
    return request.post("/hawksystemserver/airspace/upload", params);
  }

  //检查航线
  static checkline(params) {
    return request.post("/hawksystemserver/flight/checkline", params);
  }

}