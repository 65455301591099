// 首页飞行任务接口
import { axios } from "../request";

class FLYCOM {
  // --------------------------------------------------------------------------飞行任务
  static dataTotal(query) { // 飞行数据统计
    return axios.get(`/hawksystemserver/home/dataTotal` + query);
  }
  static latest(page,size) { // 飞行数据统计
    // return axios.get(`/hawksystemserver/lightInfo/latest`);
    return axios.get(`/hawksystemserver/lightInfo/page/${page}/${size}`);
  }
}

export default FLYCOM;
