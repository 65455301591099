import Vue from "vue";
import interact from "interactjs";
//  例：  v-interact="{resize:[true,true,false,true],el:2}"
// 目前可以传进来 resize：控制上下左右哪个方向可以拖动放大缩小
//                   el:控制可以在第几个父元素内移动（默认位只能在父元素上移动）
Vue.directive("interact", {
  inserted(el, binding, vnode) {
    let val = binding.value;
    
    let restriction = el.parentNode
    if(val.el){  // 控制该元素可以在第几个父元素内移动，不传的话就是默认在父元素内移动
      for(let i=1;i<val.el;i++){
        restriction=restriction.parentNode
      }
    }
    let it = interact(el).draggable({
      listeners: {
        move(event) {
          const target = event.target;
          const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

          target.style.transform = "translate(" + x + "px, " + y + "px)";

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      },
      modifiers: [
        interact.modifiers.snap({
            targets: [
            interact.snappers.grid({ x: 2, y: 2 }) // 设置每次移动的像素
            ],
            range: Infinity,
            relativePoints: [ { x: 0, y: 0 } ]
        }),
        interact.modifiers.restrict({  // 设置只能在父元素上移动
            restriction: restriction,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true
        })
        ],
        inertia: true
    });
    if (val && val.resize) {  // --------------靠传进来的resize控制哪个方向能拖动
      it.resizable({
        // resize from all edges and corners
        edges: { left: val.resize[0], right: val.resize[1], bottom: val.resize[2], top: val.resize[3] },

        listeners: {
          move(event) {
            var target = event.target;
            var x = parseFloat(target.getAttribute("data-x")) || 0;
            var y = parseFloat(target.getAttribute("data-y")) || 0;

            // update the element's style
            target.style.width = event.rect.width + "px";
            target.style.height = event.rect.height + "px";

            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            target.style.webkitTransform = target.style.transform =
              "translate(" + x + "px," + y + "px)";

            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
            // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
          },
        },
        modifiers: [
          // ------------------何万柏 2022/6/15注释掉这个，因为加了这个没有写指定父元素的话会报错
          // // keep the edges inside the parent
          // interact.modifiers.restrictEdges({
          //   outer: "parent",
          // }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 100, height: 50 },
          }),
        ],

        inertia: true,
      });
    }
  },
});
