import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

export const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: "/login",
        component: () => import("@/pages/login"),
      },
      {
        path: "/videos",
        component: () => import("@/pages/videos"),
      },
      {
        path: "/new",
        component: () => import("@/components/newmenu/layout"),
        redirect: "/new/dispatch",
        children: [
          {
            path: "/new/task",
            component: () => import("@/pages/new/task"),
          },
          {
            name:"flightManagement",
            path: "/new/flightManagement",
            component: () => import("@/pages/new/flightManagement"),
          },
          {
            path: "/new/dispatch",
            component: () => import("@/pages/new/dispatch"),
          },
          {
            path: "/new/control",
            component: () => import("@/pages/platform/visual/stlView"),
            // @/pages/platform/visual/view   @/pages/new/control
          },
        ],
      },
      {
        path: "/platform",
        component: () => import("@/components/platform/layout"),
        redirect: "/platform/task",
        children: [
          {
            path: "/platform/aivideo",
            component: () => import("@/pages/platform/aivideo"),
          },
          {
            path: "/platform/realName",
            component: () => import("@/pages/platform/realName"),
          },
          {
            path: "/platform/airspace",
            component: () => import("@/pages/platform/airspace"),
          },
          {
            path: "/platform/airway",
            component: () => import("@/pages/platform/airway"),
          },
          {
            path: "/platform/poi",
            component: () => import("@/pages/platform/poi"),
          },
          {
            path: "/platform/visualization", // 可视化模式
            component: () => import("@/pages/platform/visualization"),
          },
          {
            path: "/platform/model",
            component: () => import("@/pages/platform/model"),
          },
          {
            path: "/platform/flylog",
            component: () => import("@/pages/platform/flylog"),
          },
          {
            path: "/platform/flylog-copy",
            component: () => import("@/pages/platform/flylog-copy"),
          },
          {
            path: "/platform/calendar",
            component: () => import("@/pages/platform/calendar"),
          },
          {
            // 智慧管理
            path: "/platform/wisdomgl",
            component: () => import("@/pages/platform/wisdomgl"),
          },
          {
            // 智慧调度
            path: "/platform/wisdomdd",
            component: () => import("@/pages/platform/wisdomdd"),
          },
          // {
          //   path: "/platform/flyWork",
          //   component: () => import("@/pages/platform/flyWork"),
          // },
          {
            path: "/platform/order",
            component: () => import("@/pages/platform/task"),
            redirect: "/platform/order/task",

            children: [
              {
                path: "/platform/order/achievement",
                component: () => import("@/pages/platform/task/achievement"),
              },
              {
                path: "/platform/order/task",
                meta: {
                  keepAlive: true
                },
                component: () => import("@/pages/platform/task/list"),
              },
              {
                path: "/platform/order/demand",
                component: () => import("@/pages/platform/task/list"),
              },
              {
                path: "/platform/order/handle/:id",
                component: () => import("@/pages/platform/task/handle"),
              },
              {
                path: "/platform/order/seeMore/:id",
                component: () => import("@/pages/platform/task/seeMore"),
              },
              {
                path: "/platform/order/report/:id",
                component: () => import("@/pages/platform/task/report"),
              },
              {
                path: "/platform/order/reportList",
                component: () => import("@/pages/platform/task/reportList"),
              },
              {
                path: "/platform/order/calendar",
                component: () => import("@/pages/platform/task/calendar"),
              },
            ],
          },
          {
            path: "/platform/order/visual",
            component: () => import("@/pages/platform/visual"),
            children: [
              {
                path: "/platform/order/visual/view/:type",
                component: () => import("@/pages/platform/visual/view"),
              },
              {
                path: "/platform/order/visual/jobTask/:id",
                component: () => import("@/pages/platform/visual/jobTask"),
              },
            ],
          },
          {
            path: "/platform/staff",
            component: () => import("@/pages/platform/staff"),
          },
          {
            path: "/platform/device",
            component: () => import("@/pages/platform/device"),
          },
          {
            path: "/platform/message",
            component: () => import("@/pages/platform/message"),
            redirect: "/platform/message/task",
            children: [
              {
                path: "/platform/message/warning",
                component: () => import("@/pages/platform/message/warning"),
              },
              {
                path: "/platform/message/order",
                component: () => import("@/pages/platform/message/order"),
              },
              {
                path: "/platform/message/task",
                component: () => import("@/pages/platform/message/task"),
              },
            ],
          },
          {
            path: "/platform/task",
            component: () => import("@/pages/platform/message"),
            redirect: "/platform/message/task",
            children: [
              {
                path: "/platform/task/details/:id",
                component: () =>
                  import("@/pages/platform/message/task/details"),
              },
            ],
          },
          {
            path: "/platform/aeronautical",
            component: () => import("@/pages/platform/aeronautical"),
          },
        ],
      },
      {
        path: "/counteract",
        component: () => import("@/pages/counteract"),
      },
      {
        path: "/civilAircraft",
        component: () => import("@/components/newmenu/layout"),
        redirect: "/civilAircraft/device",
        children: [
          {//飞行服务
            path: "/civilAircraft/flightService",
            component: () => import("@/pages/civilAircraft/flightService"),
          },
          {//设备管理
            path: "/civilAircraft/device",
            component: () => import("@/pages/civilAircraft/device"),
          },
          {//档案管理
            path: "/civilAircraft/certificate",
            component: () => import("@/pages/civilAircraft/certificate"),
          },
            {//报飞备案
              path: "/civilAircraft/record",
              component: () => import("@/pages/civilAircraft/record"),
            },
            {//信息管理
              path: "/civilAircraft/information",
              component: () => import("@/pages/civilAircraft/information"),
            },
        ],
      },
      {
        path: "",
        component: () => import("@/components/layout"),
        redirect: "/home",
        children: [
          {
            name: 'home',
            path: "/home",
            component: () => import("@/pages/home"),
          },

          {
            path: "/dashboard",
            component: () => import("@/pages/dashboard"),
          }
        ],
      },
    ],
  });

//解决在用vue-router 做单页应用的时候重复点击一个跳转的路由会出现报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
