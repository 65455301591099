import request from "../request";
class Device {
 /**
     * 无人机列表
     * @param {*} params 
     * @returns 
 */
    static ListDrone(params) {
        return request.get("/hawksystemserver/device/deviceListWeb", {
            params
        })
    }


 /**
     * 鹰击点击无人机展示其对应挂载
     * @param {*} params
     * @returns
     */
static getMountInfoByDeviceHardId(params) {
    return request.get('/hawksystemserver/task/getMountInfoByDeviceHardId', {
        params
    })
}


/**
     * 点击无人机展示其对应基站干扰值
     * @param {*} id
     * @returns
     */
 static getSNIRByDeviceHardId(id) {
    return request.get(`/hawksystemserver/uavTakeoffChecklist/getSNIRByDeviceHardId/${id}`)
}

  }
  
  export default Device;