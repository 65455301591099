import request from "../request";

export default class Home {
  /**
   * 设备概览
   * @param {*} data
   * @returns
   */
   static droneCount() {
    return request.get("/hawksystemserver/hnhome/get-droneCount");
  }

  /**
   * 任务运行概览
   * @param {*} data
   * @returns
   */
   static preview() {
    return request.get("/hawksystemserver/hnhome/take-preview");
  }

  /**
   * al预警运行概览
   * @param {*} data
   * @returns
   */
   static preview() {
    return request.get("/hawksystemserver/hnhome/take-preview");
  }

  /**
   * 政务量运行概览
   * @param {*} data
   * @returns
   */
   static runAnOverview() {
    return request.get("/hawksystemserver/hnhome/runAnOverview");
  }
  

  /**
   * 查询无人机
   * @param {*} data
   * @returns
   */
   static drone() {
    return request.get("/hawksystemserver/hnhome/drone");
  }

  /**
   * 飞行统计
   * @param {*} data
   * @returns
   */
   static dataTotal(data) {
    return request.get(`/hawksystemserver/hnhome/get-dataTotal?organId=${data.organId}&deviceId=${data.deviceId}&type=${data.type}`);
  }

  /**
   * 飞行统计
   * @param {*} data
   * @returns
   */
   static getFlightCount(data) {
    return request.get(`/hawksystemserver/hnhome/getFlightCount?organId=${data.organId}&deviceId=${data.deviceId}&type=${data.type}`);
  }

  
  /**
   * 
   * @param {*} data
   * @returns
   */
   static statistics() {
    return request.get(`/aiserver/kaola/statistics`);
  }

  /**
   * 运维排行榜
   * @param {*} data
   * @returns
   */
   static ranking(data) {
    return request.get(`/hawksystemserver/hnhome/ranking?startTime=${data.startTime}&endTime=${data.endTime}`);
  }
  
      /**
   * 无人机起飞检查表记录新增接口
   * @param {*} params
   * @returns
   */
       static insertUav(params) {
        return request.post('/hawksystemserver/uavTakeoffChecklist/insertUav', params)
      }




}
