import request from "../request";

export default class HomeData {
  /**
   * 设备状态统计
   * @param {*} data
   * @returns
   */
   static equipment(data) {
    return request.get(`/hawksystemserver/statistics/equipment?type=${data}`);
  }
  
  /**
   * 数据推送统计
   * @param {*} data
   * @returns
   */
   static warningPush(data) {
    return request.get(`/hawksystemserver/statistics/warningPush?timeType=${data}`);
  }

  /**
   * 预警信息
   * @param {*} data
   * @returns
   */
   static warning(params) {
    return request.get(`/hawksystemserver/statistics/warning`, { params });
  }

  /**
   * 任务成果统计
   * @param {*} data
   * @returns
   */
   static result(params) {
    return request.get(`/hawksystemserver/statistics/result`, { params });
  }
  /**
   * 预警列表
   * @param {*} params
   * @returns
   */
   static getWarningList(params) {
    return request.get(`/hawksystemserver/warning/getWarningList`, { params });
  }
/**
   * ai预警列表
   * @param {*} params
   * @returns
   */
 static aiWarningList(params) {
  return request.get(`/aiserver/kaola/warningList`, { params });
}

  
  /**
   * 预警类型
   * @param {*} params
   * @returns
   */
   static getList(params) {
    return request.get(`/hawksystemserver/warningType/getList`, { params });
  }

  
}