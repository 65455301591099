import { axios } from "../request";

class Mount {

    static GetDrdType(){
        // return axios.get(`/hawksystemserver/task-web/mountType`)
        return axios.get(`/hawksystemserver/tasks/mountType`)
    }

}

export default Mount