import Vue from 'vue'
import App from './App.vue'


Vue.config.productionTip = false

import "normalize.css/normalize.css"
import "./styles/index.scss"

//引入animate.css文件
import 'animate.css';


import "./utils/lod"

import router from "./router"
import "./router/permission"

import store from "./store"

import "./plugins"
import "./directives"
import 'leaflet/dist/leaflet.css'
// 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
import * as L from 'leaflet'

import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'video.js/dist/lang/zh-CN.js'
Vue.prototype.$video = Videojs

Vue.config.productionTip = false;
Vue.L = Vue.prototype.$L = L
import 'video.js/dist/video-js.css'
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
 
highcharts3d(highcharts)
//全局事件总线
Vue.prototype.$bus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')