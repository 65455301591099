import Vue from "vue";
// 例子：v-view="{sign:'airline:adasd'}"  通过传进来的sign字段控制元素是否存在 若sign为undefined也会让其显示

Vue.directive("view", {
  inserted(el, binding, vnode) {
    // const permissions = JSON.parse(sessionStorage.getItem("permissions"));
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    console.log(binding.value.sign)
    if (permissionList.indexOf(binding.value.sign) > -1 || binding.value.sign == undefined) {
       
    } else {
        el.remove()
    }
  },
});
