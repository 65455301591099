import { axios } from "../request";

class Model {
    static deriveMarker(params) {
        return axios.get(`/hawksystemserver/marker/deriveMarker`, { params })
    }
    static deriveMarkerKmz(params) {
        return axios.get(`/hawksystemserver/marker/deriveMarker`, { params,  responseType: "blob" })
    }

    static List(params) {
        return axios.get(`/hawksystemserver/serviceMapData/show`, { params })
    }

    static addInfo(params) {
        return axios.post(`/hawksystemserver/serviceMapData/insServiceData`, params)
    }

    static upInfo(params) {
        return axios.post('/hawksystemserver/serviceMapData/updServiceData', params)
    }

    static delInfo(params) {
        return axios.get(`/hawksystemserver/serviceMapData/delServiceData`, { params })
    }
    // 添加标注 
    static addMarker(params) {
        return axios.post(`/hawksystemserver/marker/add`, params)
    }
    static MarkerFilter(params) {
        return axios.get(`/hawksystemserver/marker/isExitCode`, { params })
    }
    static getmarkerList(params) {
        return axios.get(`/hawksystemserver/marker/list`, { params })
    }

    //   删除标签 
    static removeBatch(params) {
        return axios.get(`/hawksystemserver/marker/removeBatch`, { params })
    }
    static getIco(params) {
        return axios.get(`hawksystemserver/ico/getIco`, { params })
    }
    // 局办申请或调用次数
    static deptCount(params) {
        return axios.get(`hawksystemserver/serviceShare/deptCount`, { params })
    }

    static serviceMapDataClick(params) {
        return axios.get(`hawksystemserver/serviceMapData/click`, { params })
    }
    static serviceShareCount(params) {
        return axios.get(`hawksystemserver/serviceShare/count`, { params })
    }
    static serviceMapDataArea(params) {
        return axios.get(`hawksystemserver/serviceMapData/area`, { params })
    }

    static serviceMapDataList(params) {
        return axios.get(`hawksystemserver/serviceMapData/list`, { params })
    }

    // 添加活动

    static activityAdd(params) {
        return axios.post('/hawksystemserver/activity/add', params)
    }
    static activityList(params) {
        return axios.get(`hawksystemserver/activity/list`, { params })
    }
    static activityDel(id) {
        return axios.delete(`hawksystemserver/activity/delete/${id}`)
    }
    // 添加资源
    static activityResourceAdd(params) {
        return axios.post('/hawksystemserver/activityResource/add', params)
    }
    static activityResourceList(params) {
        return axios.get(`hawksystemserver/activityResource/list`, { params })
    }

    /**
* 活動类型列表
* @param {*} data
* @returns
*/
    static activityTypePage(params) {
        return axios.get("/hawksystemserver/activityType/page", { params });
    }
    /**
* 活動类型列表
* @param {*} data
* @returns
*/
    static activityTotalByType(params) {
        return axios.get("/hawksystemserver/activity/totalByType", { params });
    }
}




export default Model