import request from "../request";

export default class Dashboard {
    /**
   * 业务数据大屏
   * @param {*} data
   * @returns
   */
  static countDate() {
    return request.get("/hawksystemserver/hnhome/count-date");
  }

  
  /**
   * 在线业务
   * @param {*} data
   * @returns
   */
   static business() {
    return request.get("/hawksystemserver/hnhome/count-business")
  }
  
  /**
   * 消息中心推送
   * @param {*} data
   * @returns
   */
   static information() {
    return request.get("/hawksystemserver/hnhome/information")
  }

  /**
   * 飞行报告
   * @param {*} data
   * @returns
   */
   static reportTotal() {
    return request.get("/hawksystemserver/hnhome/get-reportTotal")
  }

  /**
   * 飞行业务
   * @param {*} data
   * @returns
   */
   static taskTotal() {
    return request.get("/hawksystemserver/hnhome/get-taskTotal")
  }
  
  /**
   * 作业占比
   * @param {*} data
   * @returns
   */
   static proportion() {
    return request.get("/hawksystemserver/hnhome/count-proportion")
  }
}