/*
 * @Author: kai 17660422924@163.com
 * @Date: 2024-01-12 15:49:14
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-01-12 15:55:23
 * @FilePath: \mmc-JIYuan\src\store\modules\device.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    namespaced: true,

    state: {
        flag:  true,
        elementFlag:  false,
        mapFlag:  false,
        device:  {},
        deviceHardId:  null,
        JCmodel: true,
        telescopic2: true
    },

    mutations: {
        SET_JCmodel(state, data) {
            state.JCmodel = data
        },SET_STATE(state, data) {
            state[data.key] = data.value
        },
        SET_deviceHardId(state, data) {
            state.deviceHardId = data
        },
        SET_BOX_FLAG(state, data) {
            state.flag = data
        },
        SET_ELEMENT(state, data) {
            state.elementFlag = data
        },
        SET_MAP(state, data) {
            state.mapFlag = data
        },
        SET_DEVICE(state, data) {
            state.device = data
        }
    },
    actions: {},
}
