import request from "../request";
class Staff {
    /**
   * 人员统计
   * @param {*} params
   * @returns
   */
 static Statistics(params) {
    return request.get('/hawksystemserver/person-management/user-count_v2', {
      params,
    })
  }

  /**
   * 服务举办列表数据
   * @param {*} params
   * @returns
   */
   static Check(params) {
    return request.get(
      '/hawksystemserver/person-management/cardInfos_v2',
      {params}
    )
  }

  /**
   * 服务局办图表数据
   * @param {*} params
   * @returns
   */
   static getechartData(params) {
    return request.get(
      '/hawksystemserver/person-management/flyingCounts',
      {params}
    )
  }

  /**
   * 运维人员下拉获取
   *  
   * 
   */
  static operatorList(){
    return request.get(`/hawksystemserver/task-web/operatorList`)
  }

  /**
   * 绩效排名
   * @param {*} params
   * @returns
   */
   static Rank(params) {
    return request.get('/hawksystemserver/person-management/performance-rank', {
      params,
    })
  }

  /**
   * 人员统计
   * @param {*} params
   * @returns
   */
   static userCount(params) {
    return request.get('/hawksystemserver/person-management/user-count_v2', {
      params,
    })
  }
}
export default Staff

