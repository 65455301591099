import { axios } from "../request";

class Task {
  // ------------------------------------------------------------------------新版本接口

  static getInfoByDevice(id) { // 飞行控制里面查看任务详情接口
    return axios.get("/hawksystemserver/tasks/getInfoByDevice?deviceHardId=" + id);
  }

  
  static getSelfOrganization() { // 飞行控制里面查看任务详情接口
    return axios.get("/hawksystemserver/organization/getSelfOrganization");
  }
  static sumTaskFinishNew(id) { // 总需求完成接口
    return axios.get("/hawksystemserver/tasks/sumTaskFinish?id=" + id);
  }
  static setEffect(params) { // 预警有效无效
    console.log(params,'params');
    return axios.get("/hawksystemserver/warning/setEffect",{params});
  }


  
  static getReportDetail(id) { // 任务报告详情接口
    return axios.get("/hawksystemserver/tasks/reportDetail/" + id);
  }

  static reportApplyNew(params) { // 新的任务报告审批接口
    return axios.put("/hawksystemserver/tasks/reportApply", params);
  }

  static approveMsgList(params) { //获取审批列表（自身可以审批的列表）
    function setParams(obj) {
      let result = '';
      let item;
      for (item in obj) {
        if (obj[item] && String(obj[item])) {
          result += `&${item}=${obj[item]}`;
        }
      }
      if (result) {
        result = '?' + result.slice(1);
      }
      return result;

    }
    console.log(setParams(params));
    return axios.get(`/hawksystemserver/tasks/approveMsgList/` + setParams(params));
  }

  static intuitive(params) { //获取需求单调度列表（派单）
    return axios.post(`/hawksystemserver/tasks/intuitive` + params);
  }

  static approveMsgInfo(id) { //需求详情接口
    return axios.get(`/hawksystemserver/tasks/approveMsgInfo?id=` + id);
  }
  static taskDataList(params) { // 获取单个需求单子任务列表
    return axios.get(`/hawksystemserver/taskData/taskDataList`,{params});
  }

  
  static AddNew(params) { // 发起需求接口
    return axios.post(`/hawksystemserver/tasks`, params);
  }

  static EditNew(params) { //修改需求接口
    return axios.put(`/hawksystemserver/tasks`, params);
  }
  static reCreate(params) { //修改需求接口
    return axios.post(`/hawksystemserver/tasks/reCreate`, params);
  }
  static ListNew(params) { // 任务列表获取
    return axios.post(`/hawksystemserver/tasks/page`, params);
  }

  static approvalRequirements(params) { //审批需求
    return axios.post(`/hawksystemserver/tasks/superApprove`, params);
  }
  static msgList(params) { //审批需求:飞行指挥-需求单审批列表
    return axios.get(`/hawksystemserver/tasks/msgList`, {params});
  }

  static newhandle(params) { //处理需求（单任务派单）
    return axios.put(`/hawksystemserver/tasks/handle`, params);
  }
  //-------------------------------------------------------------子任务

  static addSubtaskNew(params) { // 新建子任务
    return axios.post(`/hawksystemserver/tasks/insert`, params);
  }

  static updateSubtaskNew(params) { // 修改子任务 & 派单
    return axios.post(`/hawksystemserver/tasks/update`, params);
  }

  static taskOrderListNew(params) { // 获取单个需求单子任务列表
    return axios.get(`/hawksystemserver/tasks/taskOrderList` + params);
  }

  static getOneSubtaskNew(id) { // 派单要回显信息请求接口
    return axios.get("/hawksystemserver/tasks/" + id);
  }
  static reportDetailId(id) { // 派单要回显信息请求接口
    return axios.get(`/hawksystemserver/tasks/reportDetail/${id}`);
  }

  // -------------------------------------------------------飞行成果接口

  static resultDemandList(params) { //获取任务成果列表
    return axios.post(`/hawksystemserver/tasks/sumTaskDataSearch`, params);
  }

  static outcomeData(params) { //获取飞行管理里面的成果管理每个任务的 飞行成果-任务报告
    return axios.post(`/hawksystemserver/tasks/taskDataSearch`, params);
  }
  //图层
  static listByType(params) { //获取飞行管理里面的成果管理每个任务的 飞行成果-任务报告
    return axios.get(`/hawksystemserver/serviceMapData/listByType`, params);
  }


 
  // --------------------------------------------------------飞行日志

  static getSorties(params) { //飞行日志
    return axios.post(`/hawksystemserver/tasks/getSorties`, params);
  }

















































  static Add(params) { //发起需求接口
    // return axios.post(`/hawksystemserver/task-web`, params);
    return axios.post(`/hawksystemserver/tasks`, params);
  }

  //获取审批消息
  static postapprovalmessage(params) {
    return axios.post(`/hawksystemserver/task-web/approveMsgList`, params);
  }

  //获取审批消息详情页
  static getdetails(params) {
    return axios.get("/hawksystemserver/task-web/approveMsgInfo", { params });
  }

  //审批
  static postapproval(params) {
    return axios.post("/hawksystemserver/tasks/superApprove", params)
  }

  //修改需求
  static Edit(params) {
    return axios.put(`/hawksystemserver/task-web`, params);
  }
  //删除需求
  static Del(id) {
    return axios.delete(`/hawksystemserver/task-web/${id}`);
  }
  //重新下单
  static Again(params) {
    return axios.post(`/hawksystemserver/task-web/reCreate`, params);
  }

  static Get(id) {
    return axios.get(`/hawksystemserver/task-web/${id}`);
  }

  static EditAi(params) {
    return axios.put(`/hawksystemserver/task-web/selectAi`, params);
  }

  static List(params) {
    return axios.post(`/hawksystemserver/task-web/page`, params);
  }


  static intuitive(params) {
    return axios.post(`/hawksystemserver/task-web/intuitive`, params);
  }

  static GetReport(params) {
    return axios.get(`/hawksystemserver/task-web/exportReport`, {
      params,
      responseType: "blob",
    });
  }

  static GetAchievementList(params) { //总任务飞行成果接口
    return axios.post(`/hawksystemserver/task-web/sumTaskDataSearch`, params);
  }

  static GetSubTaskAchievementList(params) { //子任务飞行成果接口
    return axios.post(`/hawksystemserver/task-web/taskDataSearch`, params);
  }

  static GetDrdCate() {
    // return axios.get(`/hawksystemserver/task-web/sceneType`);
    return axios.get(`/hawksystemserver/tasks/sceneType`);
  }

  static GetDrdType() {
    // return axios.get(`/hawksystemserver/task-web/taskCate`);
    return axios.get(`/hawksystemserver/tasks/taskCate`);
  }

  static GetNeedsMessageList(params) {
    return axios.post(`/hawksystemserver/task-web/needsMsgList`, params);
  }

  static GetApproveMessageList(params) {
    return axios.post(`/hawksystemserver/task-web/approveMsgList`, params);
  }
  // 审批报告
  static reportApply(params) {
    return axios.put(`/hawksystemserver/task-web/reportApply`, params);
  }
  // 修改报告
  static updateReport(params) {
    return axios.put(`/hawksystemserver/task-web/updateReport`, params);
  }
  // 修改报告
  static updateReport_tasks(params) {
    return axios.put(`/hawksystemserver/tasks/updateReport`, params);
  }
  // 航线下拉查询
  static Drd(params) {
    return axios.get("/hawksystemserver/flight/getFlightLineList", {
      params
    })
  }

  // 车辆下拉查询
  static VehicleList() {
    return axios.get("/hawksystemserver/VehicleType/downList")
  }


  // 电池下拉列表
  static BatteryList(params) {
    return axios.get('/hawksystemserver/battery/getBatteryList', {
      params: params,
    })
  }

  // 运维人员列表
  static flyingUser(params) {
    // return axios.get('/hawksystemserver/task-web/flyingUser', { params })
    return axios.get('/hawksystemserver/tasks/flyingUser', { params })
  }

  // 报告下载
  static download(params) {
    return axios.get('/hawksystemserver/task-web/exportReport', {
      params,
      responseType: "blob",
    })
  }

  // 添加标签
  static tagAdd(params) {
    return axios.post('/hawksystemserver/tag/add', params)
  }
  // 删除标签
  static dropAdd(id) {
    return axios.delete(`/hawksystemserver/tag/drop/${id}`)
  }
  // 标签列表
  static tagList(params) {
    return axios.get('/hawksystemserver/tag/list', { params })
  }
  // 统计点线面标注数量
  static totalMarkByType(params) {
    return axios.get('/hawksystemserver/marker/totalMarkByType', { params })
  }
 
  // 修改标签
  static updateTag(params) { //修改
    return axios.put(`/hawksystemserver/tag/update`, params);
  }
// 添加标签类型
static addTagGroup(params) { 
  return axios.post(`/hawksystemserver/tag-group/add`, params);
}
  // 标签类型列表
  static getTagGroupList(params) {
    return axios.get('/hawksystemserver/tag-group/page', { params })
  }
 // 修改标签类型
 static updateGroup(params) { //修改
  return axios.put(`/hawksystemserver/tag-group/update`, params);
}
  
  // 删除标签类型
  static tagGroupDelete(params) {
    return axios.delete(`/hawksystemserver/tag-group/delete`,{params} );
  }
  // 飞行成果照片下载
  static updownload(params) {
    return axios.get('/upload/download', {
      params,
      responseType: "blob",
    })
  }

  // 海保处理需求
  static handle(params) {
    return axios.put(`/hawksystemserver/task-web/handle`, params);
  }

  // 移除需求处理消息
  static removeMsg(params) {
    return axios.get('/hawksystemserver/task-web/removeMsg', { params })
  }

  // 资料重命名
  static taskDataUpdate(params) {
    // return axios.post(`/hawksystemserver/task-web/taskDataUpdate`, params);
    return axios.post(`/hawksystemserver/tasks/taskDataUpdate`, params);//新版本
  }

  // 预览航线
  static viewFlight(id) {
    return axios.get(`/hawksystemserver/task-web/viewFlight/${id}`);
  }

    // 预览航线
    static flightLineInfo(id) {
      return axios.get(`/hawksystemserver/flight/flightLineInfo?id=${id}`);
    }
 
  // 历史轨迹
  static historicalTrack(params) {
    return axios.get(`/hawksystemserver/historical/getTracks`, { params });
  }


  // 飞行日志
  static taskLog(params) {
    return axios.post("/hawksystemserver/task-web/taskLog", params);
  }

  // 飞行日志下载
  static exportData(params) {
    return axios.get("/hawksystemserver/task-web/exportData", { params });
  }
  // 设备历史任务
  static taskPage(params) {
    return axios.post("/hawksystemserver/task-web/Task-page", params);
  }
  // AI上传视频接口
  static Upvideo(params) {
    return axios.post("/upload/upload", params);
  }
  // 多文件上传
  static uploads(params) {
    return axios.post("/upload/uploads", params);
  }

  // 无人机架次数据
  static getMapData(params) {
    return axios.get("/devicecentersystemserver/device/logData", { params });
  }
  // 任务回放左侧的图片列表
  static getTaskLeftList(params) {
    return axios.get("/hawksystemserver/deviceFlightLog/deviceListWeb", { params });
  }
  // 无人机详情
  static getDeviceInfoById(params) {
    return axios.get("/hawksystemserver/deviceFlightLog/getDeviceInfoById", { params });
  }
  // 无人机数据统计-图表
  static flightStatisticsIco(params) {
    return axios.get("/hawksystemserver/deviceFlightLog/flightStatisticsIco", { params });
  }
  // 无人机数据统计-列表
  static flightStatisticsList(params) {
    return axios.get("/hawksystemserver/deviceFlightLog/flightStatisticsList", { params });
  }
  // 飞行成果上传附件
  static addData(params) {
    return axios.post("/hawksystemserver/taskData/addData", params);
  }

  // ========================================================================================总任务
  // 获取总任务报告详情
  static getGeneralTask(id) {
    return axios.get("/hawksystemserver/task-web/sumReportDetail/" + id);
  }
  // 总任务报告修改
  static updateSumReport(params) {
    return axios.put("/hawksystemserver/task-web/updateSumReport", params);
  }
  // 总任务任务报告下载
  static SumReportExport(id) {
    return axios.get(`/hawksystemserver/task-web/exportReport?id=` + id, {
      responseType: "blob",
    });
  }
  // 总任务任务报告审批
  static SumReportApply(params) {
    return axios.put(`/hawksystemserver/task-web/sumreportApply`, params);
  }
  // 总任务点击完成按钮接口
  static sumTaskFinish(id) {
    return axios.get(`/hawksystemserver/task-web/sumTaskFinish?id=` + id);
  }


  // =========================================================================================子任务
  // 子任务-列表
  static taskOrderList(params) {
    return axios.get("/hawksystemserver/taskOrder/taskOrderList", { params });
  }
  // 创建子任务
  static addSubtask(params) {
    return axios.post("/hawksystemserver/taskOrder/insert", params);
  }
  // 删除子任务
  static delSubtask(id) {
    return axios.get("/hawksystemserver/taskOrder/delete/" + id);
  }
  // 获取子任务详情
  static getOneSubtask(id) {
    return axios.get("/hawksystemserver/taskOrder/info/" + id);
  }
  // 修改子任务
  static updateSubtask(params) {
    return axios.post("/hawksystemserver/taskOrder/update", params);
  }
  // 取消子任务
  static cancelSubtask(id) {
    return axios.get("/hawksystemserver/taskOrder/cancel?id=" + id);
  }
  // 子任务任务报告详情
  static subtaskReportDetail(id) {
    return axios.get("/hawksystemserver/taskOrder/reportDetail/" + id);
  }
  // 子任务任务报告编辑
  static editSubtaskReport(params) {
    return axios.put(`/hawksystemserver/taskOrder/updateReport`, params);
  }
  // 子任务任务报告审批
  static subtaskReportApply(params) {
    return axios.put(`/hawksystemserver/taskOrder/reportApply`, params);
  }
  // 子任务任务报告下载
  static subtaskReportExport(id) {
    return axios.get(`/hawksystemserver/taskData/reportExport?id=` + id, {
      responseType: "blob",
    });
  }
  // 预警类别下拉
  static getAlertTypeDropDown(params) {
    return axios.get(`/hawksystemserver/warningType/getList`, { params });
  }

  // 飞行日志统计table下拉
  static taskNameList(params) {
    return axios.get(`/hawksystemserver/deviceFlightLog/taskNameList`, { params });
  }

  //获取子任务详情
  static getOneSubtask(id) {
    return axios.get("/hawksystemserver/taskOrder/info/" + id);
  }

  // 首页数据获取
  static Equipment(params) {
    return axios.get("/hawksystemserver/hnhome/main_equipment", { params });
  }

  // 新版需求单列表
  static intuitive(params) {
    return axios.post("/hawksystemserver/tasks/intuitive", params);
  }

  // 飞行任务任务数统计
  static taskCount(params) {
    return axios.get("/hawksystemserver/statistics/taskCount", { params });
  }
  // 飞行任务任务数统计
  static taskTotalList(params) {
    return axios.get("/hawksystemserver/home/taskTotalList", { params });
  }
  // 飞行任务任务数统计
  static taskCountList(params) {
    return axios.get("/hawksystemserver/statistics/taskCountList", { params });
  }
  // 任务审批统计
  static taskApplyCount(params) {
    return axios.get("/hawksystemserver/statistics/taskApplyCount", { params });
  }

  // 预警列表
  static getWarningList(params) {
    return axios.get("/hawksystemserver/warning/getWarningList", { params });
  }
  // 设置预警为已读
  static setRead(params) {
    return axios.get("/hawksystemserver/warning/setRead", { params });
  }
  // 首页-数据分析统计
  static analyse(params) {
    return axios.get("/hawksystemserver/statistics/analyse", { params });
  }
  // 首页-任务统计
  static taskTotal(params) {
    return axios.get("/hawksystemserver/statistics/taskTotal", { params });
  }
  // 应用场景占比
  static getScenesCount(params) {
    return axios.get("/hawksystemserver/hnhome/getScenesCount", { params });
  }
  

  // 首页-模型标注统计
  static getMarkerCount(params) {
    return axios.get("/hawksystemserver/statistics/marker", { params });
  }

  static taskRank(params) { // 任务排行榜
    return axios.get(`/hawksystemserver/statistics/task-rank`, {params});
  }
  static flyRank(params) { // 任务排行榜
    return axios.get(`/hawksystemserver/statistics/fly-rank`, {params});
  }

  
  static personRank(params) { // 人员作业时长
    return axios.get(`/hawksystemserver/statistics/person-rank`, {params});
  }
  static deviceRank(params) { // 设备飞行时长
    return axios.get(`/hawksystemserver/statistics/device-rank`, {params});
  }
}

export default Task;
