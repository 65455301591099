export default {
    /** 用户信息 */
    user_info_hn: state => state.user.user_info_hn,
    /**人员看板和设备看板的下拉框数据**/
    dbox_data: state => state.user.DBOX_DATA,
    /**无人机列表**/
    fly_manage_data:state => state.user.FLY_MANAGE_DATA,
    /**切换图层**/
    my_layers_data: state => state.user.MY_LAYERS_DATA,
    //预警需求审批消息总数
    message_total_count: state => state.user.MESSAGE_TOTAL_COUNT,
}