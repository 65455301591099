import Lockr from "lockr";
import axios from "axios";
import qs from "qs";
import { message } from "@/components/message";

import store from "../store";
import router from "../router";

const { VUE_APP_BASE_URL, NODE_ENV } = process.env;
const isDev = NODE_ENV === "development" ? true : false;

const request = axios.create({
  baseURL: '/api', //isDev ? '/api' : VUE_APP_BASE_URL,
  timeout: 100000,
});

request.interceptors.request.use(
  (config) => {


    if (config.qs == true) {
      config.url =
        config.url + "?" + qs.stringify(config.params, { indices: false });
      config.params = null;
    }
    if (config.url)
      if (Lockr.get("FLYINGSESSIONID")) {
        config.headers.FLYINGSESSIONID = Lockr.get("FLYINGSESSIONID");
      }

    if (Lockr.get("IDENTITY")) {
      config.headers["mmc-identity"] = Lockr.get("IDENTITY");
    }
    if (config.url.indexOf('pageList') !== -1 && config.method == 'post') {
      config.headers["Content-Type"] = 'application/x-www-form-urlencoded'
    } else if (config.url.indexOf('upInfo') !== -1 && config.method == 'put') {
      config.headers["Content-Type"] = 'application/x-www-form-urlencoded'
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    // return blob
    if (response.data instanceof Blob) {
      return response.data;
    }

    let { status, message: msg, data } = response.data;

    if (status == 1 || status == 1005 || !status) {
      return response.data;
    } else if (status == 621) {
      // 用户登录已过期
      console.log('用户登录已过期', response.data);
      store.dispatch("user/Logout");
      router.go(0);
    } else {
      // message({
      //   message: msg,
      //   duration: 2000,
      //   type: "error",
      // });
    }

    return Promise.reject(response.data);
  },
  (error) => Promise.reject(error)
);

export { request as axios };

export default request;

var str = "{{ user.name }}";
var reg = /\{\{(.*)\}\}/; // => (.*) 子匹配
var result = RegExp.$1.trim(); // => user.name
