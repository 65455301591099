import { axios } from "../request";

class Scheduling {
  //值飞列表
  static List(params) {
    return axios.get(`/hawksystemserver/scheduling/getCalendar`,{params});
  }
  //获取执飞人员详情
  static getList(params) {
    return axios.get(`/hawksystemserver/scheduling/byDate`,{params});
  }
  //新建执飞人员
  static addScheduling(params) {
    return axios.post(`/hawksystemserver/scheduling/addPilot`,params);
  }
  //获取今天执飞人员
  static getToday() {
    return axios.get(`/hawksystemserver/scheduling/getToday`);
  }
  //修改执飞人员
  static upPilot(params) {
    return axios.put(`/hawksystemserver/scheduling/upPilot`,params);
  }
}

export default Scheduling;
