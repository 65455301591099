import request from "../request";

export default class User {
  /**
   * 登录
   * @param {*} data
   * @returns
   */
  static Login(data) {
    return request.post("/hawksystemserver/client/login", data);
  }
  static UpdateOrigin(data) {
    return request.post('/hawksystemserver/user/updateUserOrigin', data )
  }
  static GetOrigin(params) {
    return request.get('/hawksystemserver/user/getUserOrigin', {
      params,
    })
  }
}
