import { axios } from "../request";

class Ai {
  static List() {
    return axios.get(`/hawksystemserver/task-web/ais`);
  }
  static ailist(params) {
    return axios.get(`/aiserver/kaola/ailist`, { params });
  }
  static GetWarningList(params) {
    return axios.get(`/aiserver/kaola/list`, { params });
  }

  static GetHistoricalarningList(params) { // 历史预警接口
    return axios.get(`/hawksystemserver/warning/getWarningList?warningTypeId=${params.warningTypeId?params.warningTypeId:''}&startTime=${params.startTime}&endTime=${params.endTime}`);
  }

  static aiUpdate(params) {  // ai开启应用
    return axios.post(`/hawksystemserver/device/startAi`,  params
      );
  }

  static DeleteWarning(id) { // 删除预警消息某条数据
    return axios.delete(`/aiserver/kaola/delete/${id}`);
  }
}

export default Ai;
