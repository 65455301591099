import request from "../request";

export default class Airway {
  /**
 * 航线列表
 * @param {*} data
 * @returns
 */
  static flightLineList(params) {
    return request.get("/hawksystemserver/flight/flightLineList", { params });
  }

  static flightLineListAdc(params) {  // 全要素飞行模拟的航线列表
    return request.get("/hawksystemserver/flight/flightLineListAdc", { params });
  }

  /**
 * 所属单位下拉列表
 * @param {*} data
 * @returns
 */
   static getAllOrganizationBack() {
    // return request.get("/hawksystemserver/organization/getAllOrganizationBack");
    return request.get("/hawksystemserver/organization/getAllOrganizationWeb");
  }

  
  /**
 * 新增航线
 * @param {*} data
 * @returns
 */
   static addFlightLine(params) {
    return request.post("/hawksystemserver/flight/insFlightLine", params);
  }

  static addFlightLineAdc(params) {  // 全要素飞行模拟的新增航线
    return request.post("/hawksystemserver/flight/insFlightLineAdc ", params);
  }

  /**
 * 删除航线
 * @param {*} data
 * @returns
 */
   static deleteFlightLine(params) {
    return request.get("/hawksystemserver/flight/deleteFlightLine", { params });
  }

  static deleteFlightLineAdc(params) {   // 全要素飞行模拟的删除航线
    return request.get("/hawksystemserver/flight/deleteFlightLineAdc", { params });
  }

  
  /**
 * 修改航线
 * @param {*} data
 * @returns
 */
   static updFlightLine(params) {
    return request.post("/hawksystemserver/flight/updFlightLine", params);
  }

  static updFlightLineAdc(params) {  // 全要素飞行模拟的修改航线
    return request.post("/hawksystemserver/flight/updFlightLineAdc", params);
  }

  /**
     * 查询航线名称数量
     * @param {*} params
     * @returns
     */
   static GetFlightLineCount(params) {
    return request.post('/hawksystemserver/flight/getFlightLineCount', params)
   }

    // 修改航线安全状态
    static editIssafe(params) {
      return request.post('/hawksystemserver/flight/editIssafe', params)
    }
}
