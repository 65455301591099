import request from "../request";
class Device {
    /**
     * 无人机数据统计
     * @param {*} params 
     * @returns 
     */
    static FlightInfo(params) {
        return request.get("/hawksystemserver/home/dataTotal", {
            params
        })
    }

    static onlineDevice() {
        return request.get("/hawksystemserver/device/onlineDevice")
    }
    /**
        * 无人机列表
        * @param {*} params 
        * @returns 
    */
    static ListDrone(params) {
        return request.get("/hawksystemserver/device/deviceListWeb", {
            params
        })
    }


    /**
        * 鹰击点击无人机展示其对应挂载
        * @param {*} params
        * @returns
        */
    static getMountInfoByDeviceHardId(params) {
        return request.get('/hawksystemserver/task/getMountInfoByDeviceHardId', {
            params: params,
        })
    }

    /**
    * 设备工作状态统计
    * @param {*} params 
    * @returns 
    */
    static StatusInfo(params) {
        return request.get("/hawksystemserver/home/planeTaskTotal", {
            params
        })
    }

    /**
   * 设备工作状态统计
   * @param {*} params
   * @returns
   */
    static dataInfo(params) {
        return request.get('/hawksystemserver/home/deviceTotal2', {
            params,
        })
    }


    /**
       * 无人机飞行统计列表
       * @param {*} params 
       * @returns 
       */
    static deviceStatusTotal(params) {
        return request.get("/hawksystemserver/statistics/deviceStatusTotal", {
            params
        })
    }



    /**
      * 电池列表
      * @param {*} params 
      * @returns 
      */
    static batteryList(params) {
        return request.get("/hawksystemserver/battery/selectLikeListWeb", {
            params
        })
    }


    /**
     * 无人机管理
     * @param {*} params 
     * @returns 
     */
    static deviceListWeb(params) {
        return request.get("/hawksystemserver/device/deviceListWeb", {
            params
        })
    }

    /**
     * 挂载列表
     * @param {*} params 
     * @returns 
     */
    static ListMount(params) {
        return request.get("/hawksystemserver/mount/mountListAll", {
            params
        })
    }




    // 机构无人机列表
    static organTree(params) {
        return request.get("/hawksystemserver/device/organTree4", {
            params
        })
    }
    static deviceList(params) {
        return request.get("/hawksystemserver/device/deviceList", {
            params
        })
    }
    static devicecenterSystemServerData(params) {
        return request.get(`/devicecentersystemserver/device/data`, {
            params
        })
    }
    static querydaily(params) {
        return request.get(`/v1/record/querydaily`, {
            params
        })
    }
    static getStream(params) {
        return request.get(`/hawksystemserver/device/getStream?deviceHardId=${params}&type=0`)
    }
    static getHistoryStream(params) {
        return request.get("/hawksystemserver/device/getHistoryStream", {
            params
        })
    }










    // 挂载列表-------------------------
    static mountList(params) {
        return request.get("/hawksystemserver/mount/mountList", {
            params
        })
    }
    /**
   * 基站列表
   * @param {*} params 
   * @returns 
   */
    static ListBaseStation(params) {
        return request.get("/hawksystemserver/base/baseList", {
            params
        })
    }
    /**
    * 电池列表
    * @param {*} params 
    * @returns 
    */
    static selectLikeList(params) {
        return request.get("/hawksystemserver/battery/selectLikeList", {
            params
        })
    }

    /**
* 机库
* @param {*} params 
* @returns 
*/
    static landList(params) {
        return request.get("/hawksystemserver/land/landList", {
            params
        })
    }
    /**
    * 无人机起飞检查表记录新增接口
    * @param {*} params
    * @returns
    */
    static vehicleGetList(params) {
        return request.post(`/hawksystemserver/Vehicle/getList?pageNum=${params.pageNum}&pageSize=${params.pageSize}`)
    }




    /**
    * 服务数据列表
    * @param {*} params
    * @returns
    */
    static serviceDatalList(params) {
        return request.get(`/hawksystemserver/serviceMapData/list`, { params })
    }

    /**
* 申请服务数据列表
* @param {*} params
* @returns
*/
    static threeDimensionalList(params) {
        return request.get(`/hawksystemserver/serviceShare/list`, { params })
    }

    /**
    * 申请服务数据
    * @param {*} params
    * @returns
    */
    static requestServiceData(params) {
        console.log(params);
        return request.post(`/hawksystemserver/serviceShare/add`, params)
    }
    /**
    * 修改申请服务数据
    * @param {*} params
    * @returns
    */
    static updateServiceData(params) {
        return request.put(`/hawksystemserver/serviceShare/update`, { params })
    }


    /**
    * 公告分页列表
    * @param {*} params
    * @returns
    */
    static newsList(params) {
        return request.get(`/hawksystemserver/news/list`, { params })
    }

    /**
* 海宁面积
* @param {*} data
* @returns
*/
    static hainingArea(params) {
        return request.get(`/hawksystemserver/globalConfig/hainingArea`, { params })
    }

}
export default Device;