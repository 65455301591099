import Lockr, { set } from "lockr";
import { setAccount, setPassword } from "@/utils/auth";
import API from "@/api";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    set FLYINGSESSIONID(value) {
      Lockr.set("FLYINGSESSIONID", value);
    },
    get FLYINGSESSIONID() {
      return Lockr.get("FLYINGSESSIONID");
    },
    set IDENTITY(value) {
      Lockr.set("IDENTITY", value);
    },
    get IDENTITY() {
      return Lockr.get("IDENTITY");
    },
    //存储
    set user_info_hn(value) {
      Lockr.set("user_info_hn", value);
    },
    //获取
    get user_info_hn() {
      return Lockr.get("user_info_hn");
    },
    //获取所属单位下拉列表
    set DBOX_DATA(data) {
      Lockr.set("dbox_data",data);
    },
    get DBOX_DATA() {
      return Lockr.get("dbox_data");
    },
    //无人机列表
    set FLY_MANAGE_DATA(data) {
      Lockr.set("fly_manage_data",data);
    },
    get FLY_MANAGE_DATA() {
      return Lockr.get("fly_manage_data");
    },
    // 首页2D地图跟街道地图路径变量存储
    set MY_LAYERS_DATA(data) {
      Lockr.set("my_layers_data",data);
    },
    // 首页2D地图跟街道地图路径变量获取
    get MY_LAYERS_DATA() {
      return Lockr.get("my_layers_data");
    },
  },

  mutations: {
    SET_FLYINGSESSIONID(state, data) {
      state.FLYINGSESSIONID = data;
    },
    SET_IDENTITY(state, data) {
      state.IDENTITY = data;
    },
    //data赋值给user_info
    SET_USER_INFO(state, data) {
      state.user_info_hn = data;
    },
    CLEAR_USER_INFO(state) {
      state.FLYINGSESSIONID = undefined;
      state.IDENTITY = undefined;
      state.user_info_hn = undefined;
    },
    DROP_DOWN_BOX (state,data) {
      state.DBOX_DATA = data
    },
    FLY_LIST_DATA (state,data) {
      state.FLY_MANAGE_DATA = data
    },
    // 首页2D地图跟街道地图路径变量赋值
    MY_LAYERS (state,data) {
      state.MY_LAYERS_DATA = data
    },
  },

  actions: {
    Login(ctx, data) {
      return new Promise((resolve, reject) => {
        API.USER.Login({
          username: data.username,
          password: btoa(data.password),
          type: 1, // 登录端标识，平台：1 mmcstation：2 轻应用：3
        })
          .then((res) => {
            // cookie 记住用户名密码
            if (data.remember) {
              setAccount(data.username);
              setPassword(data.password);
            }
            // localStorage 记住登录凭证
            if (res.status == 1) {
            ctx.commit("SET_FLYINGSESSIONID", res.data.FLYINGSESSIONID);
            ctx.commit("SET_IDENTITY", res.data["mmc-identity"]);
            ctx.commit("SET_USER_INFO", res.data);
            localStorage.setItem('permissions',JSON.stringify(res.data.permissions))
            }
          resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    Logout(ctx) {
      ctx.commit("CLEAR_USER_INFO");
      router.go(0);
    },
    Dbox(ctx) {
        let { res } =  API.AIRWAY.getAllOrganizationBack().then((res)=>{
          ctx.commit("DROP_DOWN_BOX",res.data);
        })
    },
    // 首页2D地图跟街道地图切换
    Mylayers(ctx,data) {
      // ctx.commit("DROP_DOWN_BOX",res.data);
      // console.log('ctx:',ctx);
      // console.log('data:',data);
      ctx.commit("MY_LAYERS",data);
    },
  },
};
