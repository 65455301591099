import { axios } from "../request";


class Poi {
  // 元素列表
  static List(params) {
    return axios.get(`/hawksystemserver/info/getInfo`, { params });
  }


  // 元素图表列表
  static IcoList(params) {
    return axios.get(`/hawksystemserver/ico/getIco`, { params });
  }


  // 元素图表列表
  static getType(params) {
    return axios.get(`/hawksystemserver/type/getType`, { params });
  }

  // 新增元素
  static addInfo(params) {
    return axios.post(`/hawksystemserver/info/addInfo`, params);
  }

  // 修改元素
  static upInfo(params) {
    return axios.put(`/hawksystemserver/info/upInfo`, params);
  }

  // 模板下载
  static downloadMould() {
    return axios.get(`/upload/download?key=elementIco/导入要素模板.xlsx`,{
        responseType: "blob"
    })
  }

  // 导入要素
  static uploadYaosu(params) {
    return axios.post(`/hawksystemserver/info/upload`, params
    )
  }
}

export default Poi;
