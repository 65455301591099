import { axios } from "../request";

class Uav {
  static GetDrdType() {
    // return axios.get(`/hawksystemserver/task-web/deviceCate`);
    return axios.get(`/hawksystemserver/tasks/deviceCate`);
  }
  static getDeviceOfTask(diviceId) {
    return axios.get(`/hawksystemserver/ground-station/getDeviceOfTask?deviceHardId=${diviceId}`);
  }

  static taskUpdate(data) {
    return axios.post(`/hawksystemserver/ground-station/task-update`, data);
  }

  /**
     * 查询任务
     * @param {*} params
     * @returns
     */
  static selectLikeList(params) {
    return axios.get("/hawksystemserver/ground-station/selectLikeList", {
      params,
    });
  }
  /**
   * 根据硬件 id 查询无人机最新数据
   * @param {*} params
   * @returns
   */
  static GetLastUavData(params) {
    return axios.get("/hawksystemserver/task/getMountInfoByDeviceHardId", {
      params,
    });
  }

  // 记录日志
  static uavOperationLog(params) {
    return axios.get('/hawksystemserver/log/uavOperationLog', {
      params,
    })
  }
  // 记录日志
  static majorEquipment(params) {
    return axios.get('/hawksystemserver/hnhome/main_equipment', {
      params,
    })
  }
}

export default Uav;
